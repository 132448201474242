import React from 'react'

const NotFoundPage = () => (
  <div>
    <h1>OOPS</h1>
    <p>It seems this page doesn't exist. Sorry about that!</p>
  </div>
)

export default NotFoundPage
